import logo from "../src/images/main-logo.png";
import "./App.css";
import { useState, useEffect } from "react";
import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  TextareaAutosize,
  MenuItem,
  Button,
} from "@mui/material";
import store from "./images/store.png";
import profile from "../src/images/profile.png";
import cart from "../src/images/cart.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import delivery from "./images/delivery-time.png";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
// import { Button } from "bootstrap";
// import { Routes, Route } from "react-router-dom";

function JobsApply() {
  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      {/* <Routes>
        <Route path="products/ProductDetail" element={<ProductDetail />} />
      </Routes> */}
      <Grid container spacing={2} className="header-top">
        <Grid item md={3} textAlign={"center"}>
          {/* <Box src={cod} component="img" mr={1} /> */}
          <Box src={logo} component="img" />
        </Grid>
        <Grid item md={6}></Grid>
        <Grid item md={3} textAlign={"center"}>
          <Box className="header-icons">
            <Box src={profile} component="img" mr={2} />
            <Box src={cart} component="img" />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} className="w-100">
          <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
            <Container>
              {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link href="#home">Home</Nav.Link>
                  <NavDropdown title="Groceries" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">
                      Groceries
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="#ourstory">Our Story</Nav.Link>
                  <Nav.Link href="#fieldnotes">Field Notes</Nav.Link>
                  <Nav.Link href="#link">Demo</Nav.Link>
                  <NavDropdown title="Demo" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Demo</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box className="jobshero"></Box>
        </Grid>
      </Grid>
      <Box className="jobs-apply-block">
        <Grid container spacing={2} marginTop={3}>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl className="cs-width">
                  <Typography component="label" className="dropdown-label">
                    Position Applying For
                  </Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    defaultValue="Suppliers"
                  >
                    <MenuItem value="Suppliers">Select</MenuItem>
                    <MenuItem value="car">Cashier</MenuItem>
                    <MenuItem value="van">Manager</MenuItem>
                    <MenuItem value="jeep">Chef</MenuItem>
                    <MenuItem value="sccoty">Waiter</MenuItem>
                    <MenuItem value="wagon">Cleaning Staff</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl className="cs-width">
                  <Typography component="label" className="dropdown-label">
                    Locations
                  </Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    defaultValue="Suppliers"
                  >
                    <MenuItem value="Suppliers">Select</MenuItem>
                    <MenuItem value="car">Hyderabad</MenuItem>
                    <MenuItem value="van">Visakhapatnam</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography component="h3">AVAILABILITY</Typography>
                <FormControl>
                  {/* <FormLabel id="demo-controlled-radio-buttons-group">
                    Gender
                  </FormLabel> */}

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    defaultValue="female"
                    name="row-radio-buttons-group"
                    size="small"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Full - Time"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Part - Time"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Nights or Weekends"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={3}>
          <Grid item md={12}>
            <Typography component="h3">PERSONAL INFORMATION</Typography>
          </Grid>

          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput
                placeholder="First Name"
                className="fixed-height"
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Last Name" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Email" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput
                placeholder="Phone Number"
                className="fixed-height"
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <TextareaAutosize
              className="textarea-cs"
              placeholder="Address 1"
              component="textarea"
            ></TextareaAutosize>
          </Grid>
          <Grid item md={12}>
            <TextareaAutosize
              className="textarea-cs"
              placeholder="Address 2"
              component="textarea"
            ></TextareaAutosize>
          </Grid>
          <Grid item md={4}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="City" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="State" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Pincode" className="fixed-height" />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={3}>
          <Grid item md={12}>
            <Typography component="h3">WORK HISTORY</Typography>
          </Grid>
          <Grid item md={12}>
            <FormControl className="cs-width">
              <OutlinedInput
                placeholder="Previous Job Title"
                className="fixed-height"
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput
                placeholder="Date Previous Job Started"
                className="fixed-height"
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput
                placeholder="Date Previous Job Ended"
                className="fixed-height"
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <TextareaAutosize
              className="textarea-cs"
              placeholder="Previous Job Description"
              component="textarea"
            ></TextareaAutosize>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={3}>
          <Grid item md={12}>
            <Typography component="h3">REFERENCES</Typography>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Name 1" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Phone 1" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Name 2" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Phone 2" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Name 3" className="fixed-height" />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl className="cs-width">
              <OutlinedInput placeholder="Phone 3" className="fixed-height" />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={3}>
          <Box textAlign={"center"} className="w-100">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Box>
    </div>
  );
}

export default JobsApply;
