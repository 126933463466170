import logo from "../src/images/main-logo.png";
import "./App.css";
import { useState, useEffect } from "react";
import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Divider,
} from "@mui/material";
import store from "./images/store.png";
import profile from "../src/images/profile.png";
import cart from "../src/images/cart.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import delivery from "./images/delivery-time.png";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import SwiperSlider from "./SweeperSlider";
import item1 from "../src/images/item1.png";
import star from "../src/images/star.png";
import { Button, Form } from "react-bootstrap";
import item2 from "../src/images/item2.png";
import item3 from "../src/images/item3.png";
import item4 from "../src/images/item4.png";
import ProductDetailimg from "../src/images/ProductDetail.jpg";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GroupedButtons from "./GroupedButtons";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import cross from "../src/images/cross.svg";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
// import { Routes, Route } from "react-router-dom";

function Cart() {
  return (
    <div>
      {/* <Routes>
        <Route path="products/ProductDetail" element={<ProductDetail />} />
      </Routes> */}
      <Grid container spacing={2} className="header-top">
        <Grid item md={3} textAlign={"center"}>
          {/* <Box src={cod} component="img" mr={1} /> */}
          <Box src={logo} component="img" />
        </Grid>
        <Grid item md={6}></Grid>
        <Grid item md={3} textAlign={"center"}>
          <Box className="header-icons">
            <Box src={profile} component="img" mr={2} />
            <Box src={cart} component="img" />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
            <Container>
              {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link href="#home">Home</Nav.Link>
                  <NavDropdown title="Groceries" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">
                      Groceries
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="#ourstory">Our Story</Nav.Link>
                  <Nav.Link href="#fieldnotes">Field Notes</Nav.Link>
                  <Nav.Link href="#link">Demo</Nav.Link>
                  <NavDropdown title="Demo" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Demo</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingX={5}>
        <Grid item md={12}>
          <Box className="content-centered">
            <Grid container spacing={2} mb={2}>
              <Grid item md={6}>
                <Typography className="cart-heading">Shopping Bag</Typography>
              </Grid>
              <Grid item md={6} textAlign={"right"}>
                <FormControl>
                  <OutlinedInput
                    placeholder="Search Bag"
                    className="fixed-height"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Divider></Divider>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box className="shop-block">
                  <Checkbox {...label} />
                  <Typography className="shop-name" component="p">
                    Sri Srinivasa Veggies
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={9}>
                <Box className="items-block">
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <Box
                        className="item-img-box"
                        component="img"
                        src={item1}
                      />
                    </Grid>
                    <Grid item md={9}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography component="p" className="item-name">
                          New Potatoes - From Farm
                        </Typography>
                        <Box component="img" src={cross} />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        className="fixed-top-item-card"
                      >
                        <FormControl className="">
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select viewmore-dpdown-detail dpdown-item-card"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="Suppliers"
                          >
                            <MenuItem value="Suppliers">Select</MenuItem>
                            <MenuItem value="car">Original</MenuItem>
                            <MenuItem value="van">2nd Quality</MenuItem>
                          </Select>
                        </FormControl>
                        <Typography component="p" className="item-price">
                          ₹126.00
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <Box
                        className="item-img-box"
                        component="img"
                        src={item2}
                      />
                    </Grid>
                    <Grid item md={9}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography component="p" className="item-name">
                          New Potatoes - From Farm
                        </Typography>
                        <Box component="img" src={cross} />
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        className="fixed-top-item-card"
                      >
                        <FormControl className="">
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select viewmore-dpdown-detail dpdown-item-card"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="Suppliers"
                          >
                            <MenuItem value="Suppliers">Select</MenuItem>
                            <MenuItem value="car">Original</MenuItem>
                            <MenuItem value="van">2nd Quality</MenuItem>
                          </Select>
                        </FormControl>
                        <Typography component="p" className="item-price">
                          ₹126.00
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box className="items-block">
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography component="p" className="item-total">
                      Item Total
                    </Typography>
                    <Typography component="p" className="item-total">
                      ₹162.00
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mt={1}
                  >
                    <Typography component="p" className="item-total">
                      GST
                    </Typography>
                    <Typography component="p" className="item-total">
                      ₹29.00
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mt={1}
                    mb={1}
                  >
                    <Typography component="p" className="item-total">
                      Taxes
                    </Typography>
                    <Typography component="p" className="item-total">
                      ₹29.00
                    </Typography>
                  </Box>
                  <Divider></Divider>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mt={1}
                  >
                    <Typography component="p" className="item-total">
                      Grand Total
                    </Typography>
                    <Typography component="p" className="item-total">
                      ₹200.00
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Button className="w-100" href="/Invoice">
                      Checkout
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Cart;
