import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import fruitandveg from "./images/shop-img1.jpg";
import meatfish from "./images/shop-img2.jpg";
import cheesediary from "./images/shop-img3.jpg";
import bakery from "./images/shop-img4.jpg";
import storecupboard from "./images/shop-img5.jpg";
import minic1 from "./images/mini-c1.jpg";
import minic2 from "./images/mini-c2.jpg";
import minic3 from "./images/mini-c3.jpg";
import minic4 from "./images/mini-c4.jpg";
import minic5 from "./images/mini-c5.jpg";
import minic6 from "./images/mini-c6.jpg";
import minic7 from "./images/mini-c7.jpg";
import minic8 from "./images/mini-c8.jpg";
import minic9 from "./images/mini-c9.jpg";
import minic10 from "./images/mini-c10.jpg";
import { Card, Typography, Box } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function CategorySlider(props) {
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      // showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      //   infinite={true}
      //   autoPlay={props.deviceType !== "mobile" ? true : false}
      //   autoPlaySpeed={1000}
      keyBoardControl={true}
      // customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      //   deviceType={props.deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      <div className="custom-slider">
        <Box>
          <Box src={minic1} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Fruit & Vegetables
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic2} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Skin Care
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic3} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Baby Care
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic4} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Beverages
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic5} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Dairy
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic6} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Skin Care
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic7} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Baby Care
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic8} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Baby Care
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic9} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Baby Care
          </Typography>
        </Box>
      </div>
      <div className="custom-slider">
        <Box>
          <Box src={minic10} component="img" className="mini-slide-img" />
          <Typography gutterBottom variant="small" component="small">
            Baby Care
          </Typography>
        </Box>
      </div>
    </Carousel>
  );
}
