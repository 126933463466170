import logo from "../src/images/main-logo.png";
import "./App.css";
import { useState, useEffect } from "react";
import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import store from "./images/store.png";
import profile from "../src/images/profile.png";
import cart from "../src/images/cart.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import delivery from "./images/delivery-time.png";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import SwiperSlider from "./SweeperSlider";
import item1 from "../src/images/item1.png";
import star from "../src/images/star.png";
import { Button, Form } from "react-bootstrap";
import item2 from "../src/images/item2.png";
import item3 from "../src/images/item3.png";
import item4 from "../src/images/item4.png";
import ProductDetailimg from "../src/images/ProductDetail.jpg";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GroupedButtons from "./GroupedButtons";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import { Routes, Route } from "react-router-dom";

function ProductDetail() {
  return (
    <div>
      {/* <Routes>
        <Route path="products/ProductDetail" element={<ProductDetail />} />
      </Routes> */}
      <Grid container spacing={2} className="header-top">
        <Grid item md={3} textAlign={"center"}>
          {/* <Box src={cod} component="img" mr={1} /> */}
          <Box src={logo} component="img" />
        </Grid>
        <Grid item md={6}></Grid>
        <Grid item md={3} textAlign={"center"}>
          <Box className="header-icons">
            <Box src={profile} component="img" mr={2} />
            <Box src={cart} component="img" />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} className="w-100">
          <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
            <Container>
              {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link href="#home">Home</Nav.Link>
                  <NavDropdown title="Groceries" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">
                      Groceries
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="#ourstory">Our Story</Nav.Link>
                  <Nav.Link href="#fieldnotes">Field Notes</Nav.Link>
                  <Nav.Link href="#link">Demo</Nav.Link>
                  <NavDropdown title="Demo" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Demo</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="banner-block">
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Grid container className="text-center" justifyContent={"center"}>
                <Grid item md={8}>
                  <Box className="w-100 p-15 custom-swiper-slider">
                    {/* <SwiperSlider /> */}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Typography className="merchant-name-head" component="h1">
                Organic Potatoes
              </Typography>
              <Typography className="skuid" component="p" paddingY={1} mt={1}>
                @ Srinivasa Groceries
              </Typography>
              <Typography component="p" className="skuid" paddingY={1}>
                SKUID : GRO-POT1KG-VEG
              </Typography>
              <Box className="flex" mt={1}>
                <Typography className="skuid">Rs 50</Typography>
                <Stack spacing={1} mb={1}>
                  <Rating
                    name="half-rating-read"
                    defaultValue={4.0}
                    precision={0.5}
                    readOnly
                  />
                </Stack>
                <Typography component="span" className="badge bg-success">
                  4.0
                </Typography>
              </Box>

              <Typography className="about-merchant" component="h4">
                Indian authentic flavours with no added flavours. best in
                quality & premium taste.Indian authentic flavours with no added
                flavours. best in quality & premium taste.
              </Typography>
              <Box class="pro-view-qantity" mt={1}>
                <Button variant="outlined" className="active">
                  500 gms
                </Button>
                <Button variant="outlined">100 gms</Button>
                <Button variant="outlined">250 gms</Button>
                <Button variant="outlined">500 gms</Button>
                <Button variant="outlined">1 KG</Button>
              </Box>
              <Box pt={1}>
                <FormControl className="">
                  <Typography component="label" className="dropdown-label">
                    Flavour
                  </Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown-detail"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    defaultValue="Suppliers"
                  >
                    <MenuItem value="Suppliers">Select</MenuItem>
                    <MenuItem value="car">Original</MenuItem>
                    <MenuItem value="van">2nd Quality</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className="flex" mt={2}>
                <GroupedButtons />

                <FormControl className="flex ml-20">
                  <OutlinedInput
                    placeholder="promo code"
                    className="fixed-height"
                  />
                  <Box className="pcode custom-ml">Apply</Box>
                </FormControl>
              </Box>
              <Box mt={1}>
                <Button
                  variant="contained p-button"
                  href="/Cart"
                  className="viewmore-dpdown-detail"
                >
                  Add to Cart
                </Button>
              </Box>
              <Box className="flex" alignItems={"center"}>
                <Box className="flex" mt={1}>
                  <Box component="img" src={store} />
                  <Box>
                    <Typography component="label" ml={1} className="font-bold">
                      Store Location
                    </Typography>
                    <Typography component="p" ml={1}>
                      Hyderabad
                    </Typography>
                  </Box>
                </Box>
                <Box className="flex" mt={1} marginLeft={3}>
                  <Box component="img" src={delivery} />
                  <Box>
                    <Typography component="label" ml={1} className="font-bold">
                      Delivery Time
                    </Typography>
                    <Typography component="p" ml={1} className="text-success ">
                      {" "}
                      6 Hrs
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Accordion className="product-detail-accordion">
                <AccordionSummary
                  expandIcon={
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="ArrowDropUpIcon"
                    >
                      <path d="m7 16 4-7 4 7z"></path>
                    </svg>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="accordian-header"
                >
                  <Typography className="accordion-head">
                    Instructions
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="p-0 pe-3">
                  <Box padding={2}>
                    <Typography component="p" className="accordion-data">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat. At vero eos et accusam et justo duo
                      dolores et ea rebum. Stet clita kasd gubergren.On the
                      other hand, we denounce with righteous indignation and
                      dislike men who are so beguiled and demoralized by the
                      charms of pleasure of the moment, so blinded by desire.
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion className="product-detail-accordion">
                <AccordionSummary
                  expandIcon={
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="ArrowDropUpIcon"
                    >
                      <path d="m7 16 4-7 4 7z"></path>
                    </svg>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="accordian-header"
                >
                  <Typography className="accordion-head">
                    Specifications
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="p-0 pe-3">
                  <Box padding={2}>
                    <Typography component="p" className="accordion-data">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat. At vero eos et accusam et justo duo
                      dolores et ea rebum. Stet clita kasd gubergren.On the
                      other hand, we denounce with righteous indignation and
                      dislike men who are so beguiled and demoralized by the
                      charms of pleasure of the moment, so blinded by desire.
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion className="product-detail-accordion">
                <AccordionSummary
                  expandIcon={
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="ArrowDropUpIcon"
                    >
                      <path d="m7 16 4-7 4 7z"></path>
                    </svg>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="accordian-header"
                >
                  <Typography className="accordion-head">Offers</Typography>
                </AccordionSummary>
                <AccordionDetails className="p-0 pe-3">
                  <Box padding={2}>
                    <Typography component="p" className="accordion-data">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat. At vero eos et accusam et justo duo
                      dolores et ea rebum. Stet clita kasd gubergren.On the
                      other hand, we denounce with righteous indignation and
                      dislike men who are so beguiled and demoralized by the
                      charms of pleasure of the moment, so blinded by desire.
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="product-grid">
            <Grid item md={12} className="mt-3">
              <Box className="d-flex justify-content-between align-items-center">
                <h5 className="second-sec-heading">Fresh Fruit & Vegetables</h5>
                <a href="/AllProductsStores" className="viewall-tag">
                  View All
                </a>
              </Box>
            </Grid>
            <Grid item md={2} className="mobile-width">
              <a href="/ProductDetail">
                <Box className="product-block">
                  <Box className="product-block-inner" textAlign={"center"}>
                    <Box component="img" src={item1} className="item-img" />
                  </Box>
                  <Box className="product-block-weight" textAlign={"center"}>
                    <Typography
                      component="p"
                      className="product-block-weight-value"
                    >
                      1.0 lb
                    </Typography>
                  </Box>
                  <Box className="product-block-title-price text-center">
                    <a class="title" href="#">
                      New Potatoes
                    </a>
                    <div className="reviews mb-2">
                      <Box component="img" src={star} />
                      <Box component="img" src={star} />
                      <Box component="img" src={star} />
                      <Box component="img" src={star} />
                      <Box component="img" src={star} />
                    </div>
                    <div className="price mb-2">
                      <span class="from">From</span>

                      <span class="amount theme-money">
                        $3<sup>50</sup>
                      </span>
                    </div>
                    <Box className="">
                      <Button className="btn btn-primary">Show Options</Button>
                    </Box>
                  </Box>
                </Box>
              </a>
            </Grid>
            <Grid item md={2} className="mobile-width">
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item2} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    New Potatoes
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={2} className="mobile-width">
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item3} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    New Potatoes
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={2} className="mobile-width">
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item4} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    New Potatoes
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={2} className="mobile-width">
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item2} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    New Potatoes
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={2} className="mobile-width">
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item1} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    New Potatoes
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item md={2} className="pe-0 position-sticky">
          <Grid container className="cart-block">
            <Grid item md={12}>
              <h5 className="ms-4 cart-summary-title">Your Order</h5>
              <p className="cart-summary-section mb-0">
                You have no items in your cart
              </p>
            </Grid>
          </Grid>
          <Grid container className="cart-items">
            <Grid item md={12} className="border-top">
              <Box className="cart-items-heading">
                <h6 className="cart-heading">Best Sellers</h6>
              </Box>
            </Grid>
            <Grid item md={12} className="cart-item-details-section">
              <Box className="d-flex pb-5">
                <Box className="product-img">
                  <Box component="img" src={product1} />
                </Box>
                <Box className="product-details">
                  <a href="#" className="product-name">
                    Salted Cashews
                  </a>
                  <div className="item-weight">0.5 oz</div>
                  <div className="item-price">
                    <span className="from">from</span>
                    <span className="current-price ms-2">₹ 300</span>
                  </div>
                  <div className="button-block">
                    <button className="btn btn-primary">Show Options</button>
                  </div>
                </Box>
              </Box>
              <Box className="d-flex pb-5">
                <Box className="product-img">
                  <Box component="img" src={product2} />
                </Box>
                <Box className="product-details">
                  <a href="#" className="product-name">
                    Atlantic Shrimp
                  </a>
                  <div className="item-weight">10.0 oz</div>
                  <div className="item-price">
                    <span className="from">from</span>
                    <span className="current-price ms-2">₹ 300</span>
                  </div>
                  <div className="button-block">
                    <button className="btn btn-primary">Add to Cart</button>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} className="cart-item-details-section border-top">
              <Box className="cart-promo-section">
                <div className="cart-promo">
                  <a href="">
                    <div className="img-outer">
                      <Box component="img" src={promo} className="img-promo" />
                    </div>
                    <div className="img-overlay"></div>
                  </a>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default ProductDetail;
