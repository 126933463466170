import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MemberTable from "./MemberTable";
import AddAddressModal from "./AddAddressModal";
import EditAddressModal from "./EditAddressModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EditModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [color, setColor] = React.useState(true);
  const changeColor = () => {
    setColor((color) => !color);
  };
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
  const ref4 = useRef();
  const ref2 = useRef();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const memeberColumns = [
    "DATE",
    "DRIVER",
    "START TIME",
    "END TIME",
    "PRE TRIP CHECK",
    "POST TRIP CHECK",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content w-400">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              ADDRESS INFO
            </Typography>
            <Button
              variant="outlined"
              mt={2}
              mb={2}
              className="retail-btn bg-white"
              mr={2}
              onClick={() => ref4.current.log()}
            >
              Add New Address
            </Button>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Card className="p-15 m-15">
            <CardContent>
              <Box className="flex-between">
                <Typography component="h3" className="font-bold">
                  Invoice Address
                </Typography>
                <Button
                  variant="outlined"
                  mt={2}
                  mb={2}
                  className="retail-btn bg-white"
                  mr={2}
                  onClick={() => ref2.current.log()}
                >
                  Edit
                </Button>
                <Box
                  className={color ? "box-success" : "box-danger"}
                  onClick={changeColor}
                ></Box>
              </Box>
              <Box>
                <Typography component="label">Sri Srinivas</Typography>
                <Typography component="label">
                  Flat No 502, Prism Colony, Dwarakanagar,
                </Typography>
                <Typography component="label">
                  Near Bike Showroom, Vishakapatnam
                </Typography>
                <Box className="flex-between" mt={1}>
                  <Typography component="label">+91-9638527410</Typography>
                  <Typography component="label">Prasad@gmail.com</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Card className="p-15 m-15">
            <CardContent>
              <Box className="flex-between">
                <Typography component="h3" className="font-bold">
                  Delivery Address
                </Typography>
                <Button
                  variant="outlined"
                  mt={2}
                  mb={2}
                  className="retail-btn bg-white"
                  mr={2}
                  onClick={() => ref2.current.log()}
                >
                  Edit
                </Button>
                <Box
                  className={color ? "box-success hidden" : "box-danger hidden"}
                  onClick={changeColor}
                ></Box>
              </Box>
              <Box>
                <Typography component="label">Sri Srinivas</Typography>
                <Typography component="label">
                  Flat No 502, Prism Colony, Dwarakanagar,
                </Typography>
                <Typography component="label">
                  Near Bike Showroom, Vishakapatnam
                </Typography>
                <Box className="flex-between" mt={1}>
                  <Typography component="label">+91-9638527410</Typography>
                  <Typography component="label">Prasad@gmail.com</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Card className="p-15 m-15">
            <CardContent>
              <Box className="flex-between">
                <Typography component="h3" className="font-bold">
                  Home Address
                </Typography>
                <Button
                  variant="outlined"
                  mt={2}
                  mb={2}
                  className="retail-btn bg-white"
                  mr={2}
                  onClick={() => ref2.current.log()}
                >
                  Edit
                </Button>
                <Box
                  className={color ? "box-success hidden" : "box-danger hidden"}
                  onClick={changeColor}
                ></Box>
              </Box>
              <Box>
                <Typography component="label">Sri Srinivas</Typography>
                <Typography component="label">
                  Flat No 502, Prism Colony, Dwarakanagar,
                </Typography>
                <Typography component="label">
                  Near Bike Showroom, Vishakapatnam
                </Typography>
                <Box className="flex-between" mt={1}>
                  <Typography component="label">+91-9638527410</Typography>
                  <Typography component="label">Prasad@gmail.com</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <AddAddressModal ref={ref4} />
      <EditAddressModal ref={ref2} />
    </div>
  );
});

export default EditModal;
