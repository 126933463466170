import logo from "../src/images/main-logo.png";
import "./App.css";
import { useState, useEffect } from "react";
import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import profile from "../src/images/profile.png";
import cart from "../src/images/cart.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import box from "../src/images/box.png";
import leaf from "../src/images/leaf.png";
import check from "../src/images/check.png";
import product1 from "../src/images/p1.jpg";
import product2 from "../src/images/p2.jpg";
import promo from "../src/images/promo.PNG";
import item1 from "../src/images/item1.png";
import star from "../src/images/star.png";
import { Button, Form } from "react-bootstrap";
import item2 from "../src/images/item2.png";
import item3 from "../src/images/item3.png";
import item4 from "../src/images/item4.png";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
// import { Routes, Route } from "react-router-dom";
import Slider from "./shopslider";

function Homescreen() {
  return (
    <div>
      {/* <Routes>
        <Route path="products/ProductDetail" element={<ProductDetail />} />
      </Routes> */}
      <Grid container spacing={2}>
        <Grid item md={10}>
          <Grid container spacing={2} className="header-top">
            <Grid item md={3} textAlign={"center"}>
              {/* <Box src={cod} component="img" mr={1} /> */}
              <Box src={logo} component="img" />
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={3} textAlign={"center"}>
              <Box className="header-icons">
                <Box src={profile} component="img" mr={2} />
                <Box src={cart} component="img" />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
                <Container>
                  {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                      <Nav.Link href="#home">Home</Nav.Link>
                      <NavDropdown title="Groceries" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                          Groceries
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          Something
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link href="/JobsApply">Our Story</Nav.Link>
                      <Nav.Link href="#fieldnotes">Field Notes</Nav.Link>
                      <Nav.Link href="#link">Demo</Nav.Link>
                      <NavDropdown title="Demo" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                          Demo
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          Something
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://cdn.shopify.com/s/files/1/0571/8740/1928/files/monika-grabkowska-N-xhCO5gY7s-unsplash_1296x@2x.jpg?v=1633098768"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://cdn.shopify.com/s/files/1/0571/8740/1928/files/monika-grabkowska-qSkWlOBHia0-unsplash_1296x@2x.jpg?v=1633098518"
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://cdn.shopify.com/s/files/1/0571/8740/1928/files/shutterstock_1703274748_1296x@2x.jpg?v=1633428745"
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>
                      Praesent commodo cursus magna, vel scelerisque nisl
                      consectetur.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Grid>
          </Grid>
          <Grid container className="below-carousel">
            <Grid item md={4}>
              <Box className="d-flex">
                <Box component="img" src={box} mr={1} />
                <Box className="">
                  <p className="below-carousel-head mb-0">
                    Free Local Delivery
                  </p>
                  <Typography component="p">On all orders over $50</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="d-flex">
                <Box component="img" src={leaf} mr={1} />
                <Box className="">
                  <p className="below-carousel-head mb-0">
                    Freshness Guarantee
                  </p>
                  <Typography component="p">From farm to fork</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box className="d-flex">
                <Box component="img" src={check} mr={1} />
                <Box className="">
                  <p className="below-carousel-head mb-0">Quick Checkout</p>
                  <Typography component="p">Fill your basket faster</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="product-grid">
            <Grid item md={12} className="mt-3">
              <Box className="d-flex justify-content-between align-items-center">
                <h5 className="second-sec-heading">Fresh Fruit & Vegetables</h5>
                <a href="/AllProductsStores" className="viewall-tag">
                  View All
                </a>
              </Box>
            </Grid>
            <Grid item md={2}>
              <a href="/ProductDetail">
                <Box className="product-block">
                  <Box className="product-block-inner" textAlign={"center"}>
                    <Box component="img" src={item1} className="item-img" />
                  </Box>
                  <Box className="product-block-weight" textAlign={"center"}>
                    <Typography
                      component="p"
                      className="product-block-weight-value"
                    >
                      1.0 lb
                    </Typography>
                  </Box>
                  <Box className="product-block-title-price text-center">
                    <a class="title" href="#">
                      New Potatoes
                    </a>
                    <div className="reviews mb-2">
                      <Box component="img" src={star} />
                      <Box component="img" src={star} />
                      <Box component="img" src={star} />
                      <Box component="img" src={star} />
                      <Box component="img" src={star} />
                    </div>
                    <div className="price mb-2">
                      <span class="from">From</span>

                      <span class="amount theme-money">
                        $3<sup>50</sup>
                      </span>
                    </div>
                    <Box className="">
                      <Button className="btn btn-primary">Show Options</Button>
                    </Box>
                  </Box>
                </Box>
              </a>
            </Grid>
            <Grid item md={2}>
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item2} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    Brussel Sprouts
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item3} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    Organic Apples
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item4} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    Fennel Bulb
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item2} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    Nectarines
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box className="product-block">
                <Box className="product-block-inner" textAlign={"center"}>
                  <Box component="img" src={item1} className="item-img" />
                </Box>
                <Box className="product-block-weight" textAlign={"center"}>
                  <Typography
                    component="p"
                    className="product-block-weight-value"
                  >
                    1.0 lb
                  </Typography>
                </Box>
                <Box className="product-block-title-price text-center">
                  <a class="title" href="#">
                    Organic Beats
                  </a>
                  <div className="reviews mb-2">
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                    <Box component="img" src={star} />
                  </div>
                  <div className="price mb-2">
                    <span class="from">From</span>

                    <span class="amount theme-money">
                      $3<sup>50</sup>
                    </span>
                  </div>
                  <Box className="">
                    <Button className="btn btn-primary">Show Options</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="shop mt-3">
            <Grid item md={12} padding={3}>
              <Box className="d-flex justify-content-between">
                <Typography component="p">Shop</Typography>
                <Typography component="p">View All</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Slider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} className="pe-0 position-sticky">
          <Grid container className="cart-block ">
            <Grid item md={12}>
              {/* <Typography component="h5" classname="">
                Your Order
              </Typography> */}
              <h5 className="ms-4 cart-summary-title">Your Order</h5>
              <p className="cart-summary-section mb-0">
                You have no items in your cart
              </p>
            </Grid>
          </Grid>
          <Grid container className="cart-items">
            <Grid item md={12} className="border-top">
              <Box className="cart-items-heading">
                <h6 className="cart-heading">Best Sellers</h6>
              </Box>
            </Grid>
            <Grid item md={12} className="cart-item-details-section">
              <Box className="d-flex pb-5">
                <Box className="product-img">
                  <Box component="img" src={product1} />
                </Box>
                <Box className="product-details">
                  <a href="#" className="product-name">
                    Salted Cashews
                  </a>
                  <div className="item-weight">0.5 oz</div>
                  <div className="item-price">
                    <span className="from">from</span>
                    <span className="current-price ms-2">₹ 300</span>
                  </div>
                  <div className="button-block">
                    <button className="btn btn-primary">Show Options</button>
                  </div>
                </Box>
              </Box>
              <Box className="d-flex pb-5">
                <Box className="product-img">
                  <Box component="img" src={product2} />
                </Box>
                <Box className="product-details">
                  <a href="#" className="product-name">
                    Atlantic Shrimp
                  </a>
                  <div className="item-weight">10.0 oz</div>
                  <div className="item-price">
                    <span className="from">from</span>
                    <span className="current-price ms-2">₹ 300</span>
                  </div>
                  <div className="button-block">
                    <button className="btn btn-primary">Add to Cart</button>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} className="cart-item-details-section border-top">
              <Box className="cart-promo-section">
                <div className="cart-promo">
                  <a href="">
                    <div className="img-outer">
                      <Box component="img" src={promo} className="img-promo" />
                    </div>
                    <div className="img-overlay"></div>
                  </a>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Homescreen;
