import logo from "../src/images/main-logo.png";
import "./App.css";
import { useState, useEffect } from "react";
import * as React from "react";
import { Box, Grid, Typography, Select, MenuItem } from "@mui/material";
import profile from "../src/images/profile.png";
import cart from "../src/images/cart.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import box from "../src/images/box.png";
import leaf from "../src/images/leaf.png";
import check from "../src/images/check.png";
import product1 from "../src/images/p1.jpg";
import product2 from "../src/images/p2.jpg";
import promo from "../src/images/promo.PNG";
import item1 from "../src/images/item1.png";
import star from "../src/images/star.png";
import { Button, Form } from "react-bootstrap";
import item2 from "../src/images/item2.png";
import item3 from "../src/images/item3.png";
import item4 from "../src/images/item4.png";
import ProductDetailimg from "../src/images/ProductDetail.jpg";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GroupedButtons from "./GroupedButtons";
import CheckIcon from "@mui/icons-material/Check";
import SortIcon from "@mui/icons-material/Sort";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Slider from "./CatogorySlider";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function AllProductsStores() {
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <div>
      {/* <Routes>
        <Route path="products/ProductDetail" element={<ProductDetail />} />
      </Routes> */}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Grid container spacing={2} className="header-top">
            <Grid item md={3} textAlign={"center"}>
              {/* <Box src={cod} component="img" mr={1} /> */}
              <Box src={logo} component="img" />
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={3} textAlign={"center"}>
              <Box className="header-icons">
                <Box src={profile} component="img" mr={2} />
                <Box src={cart} component="img" />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12} className="w-100">
              <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
                <Container>
                  {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                      <Nav.Link href="#home">Home</Nav.Link>
                      <NavDropdown title="Groceries" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                          Groceries
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          Something
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link href="#ourstory">Our Story</Nav.Link>
                      <Nav.Link href="#fieldnotes">Field Notes</Nav.Link>
                      <Nav.Link href="#link">Demo</Nav.Link>
                      <NavDropdown title="Demo" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                          Demo
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          Something
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Grid>
          </Grid>
          <Box className="banner-block">
            {/* <Grid container spacing={2}>
              <Grid item md={12}>
                <Box className="bg-allproducts">
                  <Typography component="h2">In Season</Typography>
                </Box>
              </Grid>
            </Grid> */}
            <Grid
              container
              spacing={2}
              paddingBottom={2}
              className="border-top border-bottom"
            >
              <Grid item md={4}>
                <Typography component="h1" className="category-name-head">
                  Wholesale Fruits and Vegetables
                </Typography>
                <Typography component="p" className="catagory-about mt-2">
                  Shop independent brands from around the world at wholesale
                  prices.
                </Typography>
              </Grid>
              <Grid item md={8}>
                <Slider />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item md={12} className="w-100">
                <Box className="filter-block-scroll">
                  <Box className="filter-icon1">
                    <ImportExportIcon />
                  </Box>
                  <Box className="filter-icon1">
                    <SortIcon />
                  </Box>
                  <Box className="filter-dropdown">
                    <FormControl>
                      <InputLabel
                        className="ps-2"
                        id="demo-multiple-checkbox-label"
                      >
                        Availability
                      </InputLabel>
                      <Select
                        className="w-100"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Availability" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="filter-dropdown">
                    <FormControl>
                      <InputLabel
                        className="ps-2"
                        id="demo-multiple-checkbox-label"
                      >
                        Price
                      </InputLabel>
                      <Select
                        className="w-100"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Availability" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="filter-dropdown">
                    <FormControl>
                      <InputLabel
                        className="ps-2"
                        id="demo-multiple-checkbox-label"
                      >
                        Product Type
                      </InputLabel>
                      <Select
                        className="w-100"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Availability" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="filter-dropdown">
                    <FormControl>
                      <InputLabel
                        className="ps-2"
                        id="demo-multiple-checkbox-label"
                      >
                        Pack Size
                      </InputLabel>
                      <Select
                        className="w-100"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Availability" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="filter-dropdown">
                    <FormControl>
                      <InputLabel
                        className="ps-2"
                        id="demo-multiple-checkbox-label"
                      >
                        Weight
                      </InputLabel>
                      <Select
                        className="w-100"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Availability" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={2}>
              {/* <Grid item md={3}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDropUpIcon"
                      >
                        <path d="m7 16 4-7 4 7z"></path>
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="accordian-header"
                  >
                    <Typography>Availability</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="p-0 pe-3">
                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          In Stock
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          Out of Stock
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDropUpIcon"
                      >
                        <path d="m7 16 4-7 4 7z"></path>
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="accordian-header"
                  >
                    <Typography>Price</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="p" className="accordion-text">
                      Mr. Nihalchand wanted to try his luck in the family
                      profession – business. He started small to sell household
                      provisions in 1952, and named it as ‘Karachiwala Stores’
                      In Visakhapatnam.
                    </Typography>
                    <Typography variant="p" mt={1} className="accordion-text">
                      His business acumen coupled with hard work started paying
                      off, and it soon became a big hit. The main reason for its
                      success was that he could identify the potential existing
                      in the area of provisions for North Indians and others.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDropUpIcon"
                      >
                        <path d="m7 16 4-7 4 7z"></path>
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="accordian-header"
                  >
                    <Typography>Product Type</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="p-0 pe-3">
                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          Fish
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          Fruits
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          Herbs
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          Vegetables
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDropUpIcon"
                      >
                        <path d="m7 16 4-7 4 7z"></path>
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="accordian-header"
                  >
                    <Typography>Pack Size</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="p-0 pe-3">
                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          1Lb
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          2LB
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          3LB
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          4LB
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDropUpIcon"
                      >
                        <path d="m7 16 4-7 4 7z"></path>
                      </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="accordian-header"
                  >
                    <Typography>Weight</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="p-0 pe-3">
                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          1Lb
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          2LB
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          3LB
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>

                    <Box className="d-flex align-items-center justify-content-between">
                      <Box className="d-flex align-items-center">
                        <Checkbox {...label} />
                        <Typography component="p" className="filter-label">
                          4LB
                        </Typography>
                      </Box>
                      <Typography component="h2">8</Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid> */}
              <Grid item md={12}>
                <Grid container spacing={2}>
                  <Grid item md={2} className="mobile-width">
                    <a href="/ProductDetail" className="title">
                      <Box className="product-block">
                        <Box
                          className="product-block-inner"
                          textAlign={"center"}
                        >
                          <Box
                            component="img"
                            src={item1}
                            className="item-img"
                          />
                        </Box>
                        <Box
                          className="product-block-weight"
                          textAlign={"center"}
                        >
                          {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                        </Box>
                        <Box className="product-block-title-price">
                          <a className="title">NEW POTATOES FROM FARM</a>
                          <div className="reviews mb-2">
                            <Box component="img" src={star} />
                            <Box component="img" src={star} />
                            <Box component="img" src={star} />
                            <Box component="img" src={star} />
                            <Box component="img" src={star} />
                          </div>
                          <div className="price mb-2">
                            <span class="from">MRP </span>

                            <span class="amount theme-money">₹25</span>
                          </div>
                          <Box className="border-top pt-2">
                            <a href="/MerchantDetail" className="merchant-name">
                              Sri Srinivasa Vegetables
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </a>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block h-100">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item2} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          BRUSSEL SPROUTS FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="/MerchantDetail" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item3} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          ORGANIC APPLES FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="/MerchantDetail" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item4} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          FENNEL BULB FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="/MerchantDetail" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item1} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          NEW POTATOES FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="/MerchantDetail" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item2} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          BRUSSEL SPROUTS FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>
                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="/MerchantDetail" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} marginTop={1}>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item2} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          BRUSSEL SPROUTS FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item1} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          NEW POTATOES FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item4} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          FENNEL BULB FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item3} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          ORGANIC APPLES FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block h-100">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item2} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          BRUSSEL SPROUTS
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} className="mobile-width">
                    <Box className="product-block">
                      <Box className="product-block-inner" textAlign={"center"}>
                        <Box component="img" src={item1} className="item-img" />
                      </Box>
                      <Box
                        className="product-block-weight"
                        textAlign={"center"}
                      >
                        {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                      </Box>
                      <Box className="product-block-title-price">
                        <a className="title" href="#">
                          NEW POTATOES FROM FARM
                        </a>
                        <div className="reviews mb-2">
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                          <Box component="img" src={star} />
                        </div>
                        <div className="price mb-2">
                          <span class="from">MRP </span>

                          <span class="amount theme-money">₹25</span>
                        </div>
                        <Box className="border-top pt-2">
                          <a href="" className="merchant-name">
                            Sri Srinivasa Vegetables
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default AllProductsStores;
