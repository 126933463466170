import logo from "../src/images/main-logo.png";
import "./App.css";
import { useState, useEffect } from "react";
import * as React from "react";
import { Box, Grid, Typography, Select, MenuItem } from "@mui/material";
import profile from "../src/images/profile.png";
import cart from "../src/images/cart.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import box from "../src/images/box.png";
import leaf from "../src/images/leaf.png";
import check from "../src/images/check.png";
import product1 from "../src/images/p1.jpg";
import product2 from "../src/images/p2.jpg";
import promo from "../src/images/promo.PNG";
import item1 from "../src/images/item1.png";
import star from "../src/images/star.png";
import { Button, Form } from "react-bootstrap";
import item2 from "../src/images/item2.png";
import item3 from "../src/images/item3.png";
import item4 from "../src/images/item4.png";
import item5 from "../src/images/item5.jpg";
import item6 from "../src/images/item6.jpg";
import item7 from "../src/images/item7.jpg";
import item8 from "../src/images/item8.jpg";
import ProductDetailimg from "../src/images/ProductDetail.jpg";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GroupedButtons from "./GroupedButtons";
import CheckIcon from "@mui/icons-material/Check";
import SortIcon from "@mui/icons-material/Sort";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MerchantDetail() {
  const [personName, setPersonName] = useState([]);
  const [value, setValue] = React.useState(0);
  const handleClick = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <div>
      {/* <Routes>
        <Route path="products/ProductDetail" element={<ProductDetail />} />
      </Routes> */}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Grid container spacing={2} className="header-top">
            <Grid item md={3} textAlign={"center"}>
              {/* <Box src={cod} component="img" mr={1} /> */}
              <Box src={logo} component="img" />
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={3} textAlign={"center"}>
              <Box className="header-icons">
                <Box src={profile} component="img" mr={2} />
                <Box src={cart} component="img" />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
                <Container>
                  {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                      <Nav.Link href="#home">Home</Nav.Link>
                      <NavDropdown title="Groceries" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                          Groceries
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          Something
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link href="#ourstory">Our Story</Nav.Link>
                      <Nav.Link href="#fieldnotes">Field Notes</Nav.Link>
                      <Nav.Link href="#link">Demo</Nav.Link>
                      <NavDropdown title="Demo" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                          Demo
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          Something
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Grid>
          </Grid>
          <Box className="banner-block">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box className="bg-allproducts">
                  <Typography component="h2">In Season</Typography>
                </Box>
              </Grid>
            </Grid>
            <Box className="banner-block">
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Typography component="h1" className="merchant-name-head">
                    Sri Srinivasa Fruits & Vegetables
                  </Typography>
                  <Typography component="p" className="about-merchant">
                    Shop Sri Srinivasa Vegatables and other independent brands
                    on the Bazaar wholesale marketplace.
                  </Typography>
                  <Button className="w-100 border-0">Sign Up to Shop</Button>
                </Grid>
                <Grid item md={9}>
                  <Box className="border-bottom">
                    <Tabs
                      value={value}
                      onChange={handleClick}
                      aria-label="basic tabs example"
                      className="custom-tab"
                    >
                      {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                      <Tab label="All Products" {...a11yProps(1)} />
                      <Tab label="Organic" {...a11yProps(0)} />
                      <Tab label="Leafy" {...a11yProps(2)} />
                      <Tab label="Exotic" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <Box>
                    <TabPanel value={value} index={0}>
                      <Grid container spacing={2}>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item1}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                NEW POTATOES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block h-100">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item2}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                BRUSSEL SPROUTS
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item3}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                ORGANIC APPLES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item4}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                FENNEL BULB FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Grid container spacing={2}>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item5}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                NEW POTATOES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block h-100">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item6}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                BRUSSEL SPROUTS
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item7}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                ORGANIC APPLES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item8}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                FENNEL BULB FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginTop={1}>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item1}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                NEW POTATOES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block h-100">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item2}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                BRUSSEL SPROUTS
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item3}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                ORGANIC APPLES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item4}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                FENNEL BULB FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Grid container spacing={2}>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item1}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                NEW POTATOES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block h-100">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item2}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                BRUSSEL SPROUTS
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item3}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                ORGANIC APPLES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item4}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                FENNEL BULB FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Grid container spacing={2}>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item5}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                NEW POTATOES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block h-100">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item6}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                BRUSSEL SPROUTS
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item7}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                ORGANIC APPLES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item8}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                FENNEL BULB FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} marginTop={1}>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item1}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                NEW POTATOES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block h-100">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item2}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                BRUSSEL SPROUTS
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item3}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                ORGANIC APPLES FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} className="mobile-width">
                          <Box className="product-block">
                            <Box
                              className="product-block-inner"
                              textAlign={"center"}
                            >
                              <Box
                                component="img"
                                src={item4}
                                className="item-img"
                              />
                            </Box>
                            <Box
                              className="product-block-weight"
                              textAlign={"center"}
                            >
                              {/* <Typography
                          component="p"
                          className="product-block-weight-value"
                        >
                          1.0 lb
                        </Typography> */}
                            </Box>
                            <Box className="product-block-title-price">
                              <a className="title" href="#">
                                FENNEL BULB FROM FARM
                              </a>
                              <div className="reviews mb-2">
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                                <Box component="img" src={star} />
                              </div>
                              <div className="price mb-2">
                                <span class="from">MRP </span>

                                <span class="amount theme-money">₹25</span>
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default MerchantDetail;
