import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Homescreen from "./Homescreen";
import ProductDetail from "./ProductDetail";
import AllProducts from "./AllProducts";
import AllProductsStores from "./AllProductsStores";
import MerchantDetail from "./MerchantDetail";
import Cart from "./Cart";
import Invoice from "./Invoice";
import JobsApply from "./JobsApply";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homescreen />} />
        <Route path="/ProductDetail" element={<ProductDetail />} />
        <Route path="/AllProducts" element={<AllProducts />} />
        <Route path="/AllProductsStores" element={<AllProductsStores />} />
        <Route path="/MerchantDetail" element={<MerchantDetail />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Invoice" element={<Invoice />} />
        <Route path="/JobsApply" element={<JobsApply />} />
      </Routes>
    </div>
  );
}

export default App;
