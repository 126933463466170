import logo from "../src/images/main-logo.png";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  Card,
  CardContent,
  MenuItem,
  Divider,
} from "@mui/material";
import store from "./images/store.png";
import profile from "../src/images/profile.png";
import cart from "../src/images/cart.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import delivery from "./images/delivery-time.png";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import SwiperSlider from "./SweeperSlider";
import item1 from "../src/images/item1.png";
import star from "../src/images/star.png";
import { Button, Form } from "react-bootstrap";
import item2 from "../src/images/item2.png";
import item3 from "../src/images/item3.png";
import item4 from "../src/images/item4.png";
import ProductDetailimg from "../src/images/ProductDetail.jpg";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GroupedButtons from "./GroupedButtons";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import cross from "../src/images/cross.svg";
import walmart from "../src/images/purenatural.jpg";
import greenleaf from "../src/images/greenleaf.jpg";
import vegshop from "../src/images/vegshop1.jpg";
import MemberTable from "./MemberTable";
import EditModal from "./EditModal";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
// import { Routes, Route } from "react-router-dom";

const OptionsInvoice = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const customerData = [
  ["#258974", "Potatoes", "10 kg", "Rs 2000", "Rs 2100"],
  ["#258974", "Onions", "10 kg", "Rs 2000", "Rs 2080"],
  ["#258974", "Brinjal", "10 kg", "Rs 1800", "Rs 1900"],
  ["#258974", "Green Mirchi", "10 kg", "Rs 500", "Rs 520"],
  ["#258974", "Cabbage", "2 kg", "Rs 100", "Rs 100"],
  ["#258974", "Cauliflower", "2 kg", "Rs 100", "Rs 100"],
  ["", "", "", "Subtotal", "Rs 400"],
  ["", "", "", "taxes", "Rs 100"],
  ["", "", "", "Discount", "Rs 50"],
  ["", "", "", "Total", "Rs 400"],
];

const ItemColumns = ["SKU Id", "Item Name", "Quantity", "Price", "Total Price"];

function Invoice() {
  const ref3 = useRef();
  return (
    <div>
      {/* <Routes>
        <Route path="products/ProductDetail" element={<ProductDetail />} />
      </Routes> */}
      <Grid container spacing={2} className="header-top">
        <Grid item md={3} textAlign={"center"}>
          {/* <Box src={cod} component="img" mr={1} /> */}
          <Box src={logo} component="img" />
        </Grid>
        <Grid item md={6}></Grid>
        <Grid item md={3} textAlign={"center"}>
          <Box className="header-icons">
            <Box src={profile} component="img" mr={2} />
            <Box src={cart} component="img" />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
            <Container>
              {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link href="#home">Home</Nav.Link>
                  <NavDropdown title="Groceries" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">
                      Groceries
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="#ourstory">Our Story</Nav.Link>
                  <Nav.Link href="#fieldnotes">Field Notes</Nav.Link>
                  <Nav.Link href="#link">Demo</Nav.Link>
                  <NavDropdown title="Demo" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Demo</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Grid>
      </Grid>
      <Grid container spacing={2} paddingX={5}>
        <Grid item md={12}>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <Card className="bgm-gray">
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={2}>
                        <Box component="img" src={walmart} className="wx-100" />
                      </Grid>
                      <Grid item md={6}>
                        <Box>
                          <Typography
                            component="h5"
                            className="merchant-name-heading"
                            ml={2}
                          >
                            Go Green Vegetables
                          </Typography>
                          <Box ml={2}>
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              D.no : 22-15-47/1, Panjaguta Colony
                            </Typography>
                            <br />
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              Hyderbad, Telangana
                            </Typography>
                            <br />
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              Contact : +91 - 9988776610
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="p">Rs. 400</Typography>
                        <Button
                          href="/procurement/Pay"
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn bg-white"
                          mr={2}
                        >
                          Pay
                        </Button>
                      </Grid>
                    </Grid>
                    <Box className="member-table bold-data" mt={2}>
                      <MemberTable
                        columns={ItemColumns}
                        data={customerData}
                        options={OptionsInvoice}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Card className="bgm-gray">
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={2}>
                        <Box
                          component="img"
                          src={greenleaf}
                          className="wx-100"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Box>
                          <Typography
                            component="h5"
                            className="merchant-name-heading"
                            ml={2}
                          >
                            Green Leaf Vegetabes
                          </Typography>
                          <Box ml={2}>
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              D.no : 22-15-47/1, Panjaguta Colony
                            </Typography>
                            <br />
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              Hyderbad, Telangana
                            </Typography>
                            <br />
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              Contact : +91 - 9988776610
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="p">Rs. 400</Typography>
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn bg-white"
                          mr={2}
                        >
                          Pay
                        </Button>
                      </Grid>
                    </Grid>
                    <Box className="member-table bold-data" mt={2}>
                      <MemberTable
                        columns={ItemColumns}
                        data={customerData}
                        options={OptionsInvoice}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Card className="bgm-gray">
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={2}>
                        <Box component="img" src={vegshop} className="wx-100" />
                      </Grid>
                      <Grid item md={6}>
                        <Box>
                          <Typography
                            component="h5"
                            className="merchant-name-heading"
                            ml={2}
                          >
                            Deccan Vegetables Fresh Supplier & Merchant
                          </Typography>
                          <Box ml={2}>
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              D.no : 22-15-47/1, Panjaguta Colony
                            </Typography>
                            <br />
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              Hyderbad, Telangana
                            </Typography>
                            <br />
                            <Typography
                              component="span"
                              className="merchant-address"
                            >
                              Contact : +91 - 9988776610
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={3}>
                        <Typography component="p">Rs. 400</Typography>
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn bg-white"
                          mr={2}
                        >
                          Pay
                        </Button>
                      </Grid>
                    </Grid>
                    <Box className="member-table bold-data" mt={2}>
                      <MemberTable
                        columns={ItemColumns}
                        data={customerData}
                        options={OptionsInvoice}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card className="mb-15">
                  <CardContent>
                    <Box className="flex-between">
                      <Typography
                        component="h4"
                        className="address-block-heading"
                      >
                        Invoice Address
                      </Typography>
                      <Button
                        variant="outlined"
                        mt={2}
                        mb={2}
                        className="retail-btn bg-white"
                        mr={2}
                        onClick={() => ref3.current.log()}
                      >
                        Change
                      </Button>
                    </Box>
                    <Typography component="p" className="address-line" pt={1}>
                      Ram Villa, CBM Compound, Vishakhapatnam, Ram Villa, CBM
                      Compound, Vishakhapatnam
                    </Typography>
                  </CardContent>
                </Card>
                <Card className="mb-15">
                  <CardContent>
                    <Box className="flex-between">
                      <Typography
                        component="h4"
                        className="address-block-heading"
                      >
                        Delivery Address
                      </Typography>
                      <Button
                        variant="outlined"
                        mt={2}
                        mb={2}
                        className="retail-btn bg-white"
                        mr={2}
                        onClick={() => ref3.current.log()}
                      >
                        Change
                      </Button>
                    </Box>
                    <Typography component="p" className="address-line" pt={1}>
                      Ram Villa, CBM Compound, Vishakhapatnam, Ram Villa, CBM
                      Compound, Vishakhapatnam
                    </Typography>
                  </CardContent>
                </Card>
                <Card mb={2}>
                  <CardContent className="customer_information">
                    <Box className="flex-between">
                      <Typography
                        component="h4"
                        className="address-block-heading"
                      >
                        Contact Details
                      </Typography>
                      <Button
                        variant="outlined"
                        mt={2}
                        mb={2}
                        className="retail-btn bg-white"
                        mr={2}
                        onClick={() => ref3.current.log()}
                      >
                        Change
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Typography component="p" className="details-heading">
                        Name
                      </Typography>
                      <Typography component="label" className="details-value">
                        Santosh
                      </Typography>
                    </Box>
                    {/* <Box className="information-div">
                        <Typography component="label">Retailer Type</Typography>
                        <Typography>Groceries</Typography>
                      </Box> */}
                    <Box mt={1}>
                      <Typography component="p" className="details-heading">
                        Mobile No.
                      </Typography>
                      <Typography component="label" className="details-value">
                        7075554800
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography component="p" className="details-heading">
                        Email
                      </Typography>
                      <Typography component="label" className="details-value">
                        santhosh@gmail.com
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography component="p" className="details-heading">
                        Designation
                      </Typography>
                      <Typography component="label" className="details-value">
                        Manager
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <EditModal ref={ref3} />
    </div>
  );
}

export default Invoice;
